.nhs-table-caption {
  margin-bottom: 0;
}

.nhs-table-cell-clickable {
  cursor: pointer;
  text-decoration: underline;
  color: #330072;
}

.nhs-table-cell-clickable:hover {
  text-decoration: none;
  color: #7c2855;
}

.nhs-table-row-clickable {
  cursor: pointer;
}

.nhs-table-width {
  min-width: 150px;
  word-break: break-all;
}

.nhsuk-table__caption {
  caption-side: top;
  color: #212b32;
}
